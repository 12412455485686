import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class MatDialogRefStore {
  private ref: Map<string, MatDialogRef<unknown>> = new Map();
  add(key: string, ref: MatDialogRef<unknown>) {
    this.ref.set(key, ref);
  }
  get(key: string) {
    return this.ref.get(key);
  }
  remove(key: string) {
    this.remove(key);
  }
  close(key: string) {
    this.get(key)?.close();
  }
}
